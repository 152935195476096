// src目录中 新建一个request文件夹
// http.js文件用来封装我们的axios
// api.js用来统一管理我们的接口

// 在http.js中引入axios
import axios from 'axios'; // 引入axios
import store from "@/store/index"
import router from "@/router/index";

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status, other) => {
  // 状态码判断
  switch (status) {
    // 401: 未登录状态，跳转登录页
    case 401:
      toLogin();
      break;
    // 403 token过期
    // 清除token并跳转登录页
    case 403:
      tip('登录过期，请重新登录');
      localStorage.removeItem('token');
      store.commit('loginSuccess', null);
      setTimeout(() => {
        toLogin();
      }, 1000);
      break;
    // 404请求不存在
    case 404:
      tip('请求的资源不存在');
      break;
    default:
      console.log(other);
  }
}


let baseApiUrl = "";
// baseApiUrl = "http://test-pyssp.iat.wang/api/";
// baseApiUrl = "https://yaoli.changyoucn.com/api";//瑶里
// baseApiUrl = "http://127.0.0.1:4000/api";
// if (process.env.MY_ENV == "K8S") {
  baseApiUrl = "https://changyoucn.com/api";// 畅游
// } else if (process.env.JMG_ENV == "PROD") {
//   baseApiUrl = "https://test-pyssp.iat.wang/api";
// } else {
//   if (process.env.NODE_ENV === "production") {
//     baseApiUrl = "https://test-pyssp.iat.wang/api";
//   } else {
//     baseApiUrl = "http://test-pyssp.iat.wang/api/";
//   }
// }
// baseApiUrl = 'http://192.168.1.125:4000/api'
// baseApiUrl = 'http://192.168.191.42:4000/api'
// baseApiUrl = 'http://10.103.30.39:4000/api'

console.log(
  "process.env.JMG_ENV,process.env.NODE_ENV,baseUrl",
  process.env.JMG_ENV,
  process.env.NODE_ENV,
  baseApiUrl
);

// 创建axios实例
var service = axios.create({
  timeout: 60000,
  // baseURL: "http://localhost:3000/api/"
  baseURL: baseApiUrl
});


// Post请求头的设置
service.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// 请求拦截
// 先导入vuex,因为我们要使用到里面的状态对象
// 请求拦截器
service.interceptors.request.use(
  config => {
    // 登录流程控制中，根据本地是否存在token判断用户的登录情况
    // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
    // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
    // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。

    // const token = store.state.token;
    // return config;
    config.headers.Authorization = "Bearer " + store.state.UserToken;
    return config;
  },
  error => Promise.error(error))


// 响应拦截器
service.interceptors.response.use(
  // 请求成功
  res => {
    if (res.status === 200 || res.status === 201) {
      if (res.data.code === 401) {
        router.push("/");
      }
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  },
  // 请求失败
  error => {
    const { response } = error;
    if (response) {
      // 请求已发出，但是不在2xx的范围
      try {
        errorHandle(response.status, response.data.data.message);
      } catch (error) {
        errorHandle(response.statusCode, response.message);
      }
      return Promise.reject(response);
    } else {
      // 处理断网的情况
      // eg:请求超时或断网时，更新state的network状态
      // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
      // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
      if (!window.navigator.onLine) {
        store.commit('changeNetwork', false);
      } else {
        return Promise.reject(error);
      }
    }
  }
);


export default service;
